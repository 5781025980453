<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <b-card>
          <b-card-text>
            <DxGrid
              title="Customers"
              url="/pages/masterlists/customers/manage"
              isUUID
              :data-source="dataSource"
              :total-count="totalCount"
              :canAdd="getRole"
              @onToggleView="(v) => onToggleView(v)"
              @on-delete="(v) => onDelete(v)"
              @on-update="(v) => onUpdate(v)"
              @on-update-cancel="(v) => loadCustomers()"
              @on-row-click="(v) => onViewDetail(v)"
              @on-page-change="(v) => (page = v)"
              @on-size-change="(v) => (size = v)"
              @on-search="(v) => (keyword = v)"
            >
              <template #columns>
                <DxColumn
                  v-for="(item, index) in dataColumns"
                  :key="index"
                  :data-field="item.field"
                  :caption="item.caption"
                  :cell-template="item.template"
                />
              </template>
            </DxGrid>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import companiesApi from '@api/companies';
import { queryParameters } from '@/schema';

import { getUserData } from '@/auth/utils';

export default {
  name: 'CustomersPage',
  components: {},
  data: () => ({
    toggledViewData: null,
    dataSource: [],
    totalCount: 0,
    page: 0,
    size: 0,
    keyword: '',
    dataColumns: [
      { caption: 'Company ID', field: 'company_id' },
      { caption: 'Company Name', field: 'company_name' },
      { caption: 'Status', field: 'company_status' },
      { caption: 'PTC Name', field: 'ptc_name' },
      { caption: 'Phone 1', field: 'telephone_1' },
      { caption: 'Phone 2', field: 'telephone_2' },
      { caption: 'Address', field: 'address' },
    ],
  }),
  computed: {
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        return role === 'admin';
      },
    },
  },
  watch: {
    page(v) {
      this.loadCustomers();
    },
    size(v) {
      if (v !== queryParameters.page.size) {
        this.loadCustomers();
      }
    },
    keyword(v) {
      this.loadCustomers();
    },
  },
  mounted() {
    this.loadCustomers();
  },
  methods: {
    loadCustomers() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      params.page.size = this.size;
      params.page.after = this.page;

      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        params.filterExt = `id=${companyId}`;
      }

      if (this.keyword) {
        params.filter = this.keyword;
      }

      companiesApi
        .list(params)
        .then(({ data, meta }) => {
          if (data != null) {
            this.dataSource = data;

            this.totalCount = meta.page.total;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    onViewDetail(event) {
      const data = event.data;

      if (data) {
        const url = `/pages/masterlists/customers/view?id=${data.uuid}`;
        this.$router.push(url);
      }
    },
    onUpdate(event) {
      const data = event.data;
    },
    onDelete(event) {
      //
    },
  },
};
</script>

<style></style>
